import React, { useState, useEffect } from 'react';
import Visualizer from './Visualizer';
import './App.css';
import { FaPlay, FaPause } from 'react-icons/fa';

function App() {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    console.log('Loading songs...');
    setSongs([
      { title: 'regulata', src: require('./uploads/regulata4.mp3')},
      { title: 'on & on', src: require('./uploads/onandon3.mp3')},
      { title: 'wasup dr. dre', src: require('./uploads/wasup4.mp3')},
    ]);
  }, []);

  const handleSongClick = (song) => {
    console.log('Clicked song:', song.title);
    if (currentSong === song.src) {
      setIsPlaying(!isPlaying);
      console.log('Toggling play state:', !isPlaying);
    } else {
      setCurrentSong(song.src);
      setIsPlaying(true);
      setCurrentTime(0);
      console.log('Selected new song:', song.title);
    }
  };

  return (
    <div className="App">
      <main className="App-main">
        <section className="song-list">
          <h2>tracks</h2>
          <ul>
            {songs.map((song, index) => (
              <li key={index} className="song-item" onClick={() => handleSongClick(song)}>
                <span>{song.title}</span>
                {currentSong === song.src && (
                  <SongProgressBar currentTime={currentTime} duration={duration} />
                )}
                {currentSong === song.src && isPlaying ? (
                  <FaPause className="icon" />
                ) : (
                  <FaPlay className="icon" />
                )}
              </li>
            ))}
          </ul>
        </section>
        <footer>
          <div className="visualizer-container">
            {currentSong && (
              <Visualizer
                src={currentSong}
                setCurrentTime={throttleSetCurrentTime(setCurrentTime)}
                setDuration={setDuration}
                isPlaying={isPlaying}
                currentTime={currentTime}
              />
            )}
          </div>
          <p>©2024 coopa</p>
          <p>for higher-quality downloads, contact samclement@berkeley.edu</p>
        </footer>
      </main>
    </div>
  );
}

const throttleSetCurrentTime = (setCurrentTime, limit = 100) => {
  let lastCall = 0;
  return (time) => {
    const now = Date.now();
    if (now - lastCall >= limit) {
      console.log('Updating current time:', time);
      lastCall = now;
      setCurrentTime(time);
    }
  };
};

const SongProgressBar = ({ currentTime, duration }) => {
  const formatTime = (time) => {
    if (isNaN(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  console.log('Progress Bar - Current Time:', currentTime, 'Duration:', duration);

  return (
    <div className="song-progress-bar">
      <span>{formatTime(currentTime)}</span>
      <input
        type="range"
        value={currentTime}
        max={duration || 0}
        readOnly
        style={{ margin: '0 10px' }}
      />
      <span>{formatTime(duration)}</span>
    </div>
  );
};

export default App;

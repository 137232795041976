import React from 'react';
import * as THREE from 'three';

class Visualizer extends React.Component {
  componentDidMount() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.OrthographicCamera(
      -550,
      -250,
      1200,
      -200,
      200,
      5000
    );
    this.camera.position.set(400, 1000, 300);
    this.camera.lookAt(400, 0, 0);

    this.dimension = Math.min(window.innerHeight / 1.5, window.innerWidth / 1.5);

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(this.dimension, this.dimension);
    this.mount.appendChild(this.renderer.domElement);

    // Store listener as part of class properties
    this.listener = new THREE.AudioListener();
    this.camera.add(this.listener);
    
    this.sound = new THREE.Audio(this.listener);
    const audioLoader = new THREE.AudioLoader();

    // Load the audio passed as a prop
    audioLoader.load(this.props.src, (buffer) => {
      this.sound.setBuffer(buffer);
      this.sound.setVolume(1);
      if (!this.sound.isPlaying) {
        this.sound.play();
      }
      this.startTime = this.sound.context.currentTime; // Capture the start time
      this.props.setDuration(this.sound.buffer.duration);
    });

    this.analyser = new THREE.AudioAnalyser(this.sound, 512);

    this.lines = new THREE.Group();
    this.scene.add(this.lines);

    this.last = 0;

    window.addEventListener('resize', this.onWindowResize.bind(this), false);
    this.animate();
  }
  

  componentDidUpdate(prevProps) {
    // Handle source changes
    if (prevProps.src !== this.props.src) {
      if (this.sound && this.sound.isPlaying) {
        this.sound.stop();
      }
    
      this.sound = new THREE.Audio(this.listener);
      const audioLoader = new THREE.AudioLoader();
      audioLoader.load(this.props.src, (buffer) => {
        this.sound.setBuffer(buffer);
        this.sound.setVolume(1);
        this.startTime = this.sound.context.currentTime; // Reset startTime
        this.props.setDuration(this.sound.buffer.duration);
    
        if (this.props.isPlaying) {
          this.sound.play();
        }
      });
    
      this.analyser = new THREE.AudioAnalyser(this.sound, 128);
    }

    // Handle play/pause based on props.isPlaying
    if (prevProps.isPlaying !== this.props.isPlaying) {
      if (this.props.isPlaying) {
        if (!this.sound.isPlaying) {
          this.sound.play();
          this.startTime = this.sound.context.currentTime - this.props.currentTime; // Adjust startTime
        }
      } else {
        if (this.sound.isPlaying) {
          this.sound.stop();
          this.props.setCurrentTime(this.sound.context.currentTime - this.startTime);
        }
      }
    }
  }

  animate = () => {
    this.frameId = requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);

    if (this.sound.isPlaying) {
      // Update current time based on audio context's current time minus the start time
      const currentTime = this.sound.context.currentTime - this.startTime;
      this.props.setCurrentTime(currentTime);
    }

    const now = performance.now();
    if (!this.last || now - this.last >= 5) {
      this.last = now;
      const data = this.analyser.getFrequencyData();
      this.moveLines();
      this.addLine(data);
    }
  };
  addLine = (fftValues) => {
    const planeGeometry = new THREE.PlaneGeometry(200 - 1, 1, 200 - 1, 1);

    const plane = new THREE.Mesh(
      planeGeometry,
      new THREE.MeshBasicMaterial({
        color: 0x000000,
        wireframe: false,
        transparent: false,
      })
    );
    this.lines.add(plane);

    const lineGeometry = new THREE.BufferGeometry();
    let lineVertices = [];
    for (let i = 0; i < 200; i++) {
      lineVertices.push(planeGeometry.attributes.position.array[3 * i]);
      lineVertices.push(planeGeometry.attributes.position.array[3 * i + 1]);
      lineVertices.push(planeGeometry.attributes.position.array[3 * i + 2]);
    }
    lineGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(new Float32Array(lineVertices), 3)
    );

    const lineMat = new THREE.LineBasicMaterial({
      color: 0xe1e1e1,
      transparent: true,
      opacity: 0.57,
    });
    const line = new THREE.Line(lineGeometry, lineMat);

    plane.add(line);

    for (let i = 0; i < 200; i++) {
      let y = 0;
      if (i >= 39 && i < 100) {
        y += fftValues[102 - i];
      } else if (i >= 100 && i < 161) {
        y += fftValues[i - 97];
      }
      y = Math.pow(y, 1.2);

      plane.geometry.attributes.position.array[i * 3 + 1] = y;
      line.geometry.attributes.position.array[i * 3 + 1] = y;
    }
  };

  moveLines = () => {
    let planesThatHaveGoneFarEnough = [];
    this.lines.children.forEach((plane) => {
      for (let i = 0; i < 400; i++) {
        plane.geometry.attributes.position.array[i * 3 + 2] -= 1;
        if (i < 200) {
          plane.children[0].geometry.attributes.position.array[i * 3 + 2] -= 1;
        }
      }

      if (plane.geometry.attributes.position.array[2] <= -1000) {
        planesThatHaveGoneFarEnough.push(plane);
      } else {
        plane.geometry.attributes.position.needsUpdate = true;
        plane.children[0].geometry.attributes.position.needsUpdate = true;
      }
    });
    planesThatHaveGoneFarEnough.forEach((plane) => this.lines.remove(plane));
  };

  onWindowResize = () => {
    if (this.mount) {
      this.dimension = Math.min(
        window.innerHeight / 1.5,
        window.innerWidth / 1.5
      );
      this.renderer.setSize(this.dimension, this.dimension);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }
  };

  disposeAudio = () => {
    if (this.sound) {
      if (this.sound.isPlaying) {
        this.sound.stop();
      }
      this.sound.disconnect();
      this.sound = null;
    }
    this.startTime = 0; // Reset startTime
  };
  
  componentWillUnmount() {
    cancelAnimationFrame(this.frameId);
    this.disposeAudio(); // Dispose audio when component unmounts

    window.removeEventListener('resize', this.onWindowResize);

    if (this.renderer) {
      this.renderer.dispose();
      this.renderer.forceContextLoss();
      this.renderer.domElement = null;
    }

    if (this.scene) {
      while (this.scene.children.length > 0) {
        this.scene.remove(this.scene.children[0]);
      }
    }

    this.mount.innerHTML = '';
  }

  render() { 
    return <div ref={(ref) => (this.mount = ref)} className="visualizer-container" />;
  }

  
}

export default Visualizer;
